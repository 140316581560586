<template>
  <aside
    style="top: 0px"
    :class="`h-100 ${
      $store.getters['displayqruser/getAside'] ? 'sidebar collapsed' : 'sidebar'
    }`"
  >
    <div class="sidebar__menu-group">
      <ul class="sidebar_nav">
        <div @click="toggleAside()">
          <li>
            <a class="">
              <span class="span-svg" v-html="icon.main"> </span>
              <!-- <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'ProjectPage' ? 'menu-text' : 'umenu-text'
                "
                >Project</span
              > -->
            </a>
          </li>
        </div>
        <div @click="onClickMenu('ProjectPage')" class="mmt-10">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.pen"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'ProjectPage' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('JobPage')">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.file"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="$route.name == 'JobPage' ? 'menu-text' : 'umenu-text'"
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('IconTemplate')">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.cadit"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'IconTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('DocumentTemplate')" class="mt-5">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.user"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'DocumentTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('IconTemplate')">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.package"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'IconTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('IconTemplate')">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.flag"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'IconTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('IconTemplate')" class="mmt-10">
          <li v-if="1">
            <a class="">
              <span class="span-svg boxicon" v-html="icon.box"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'IconTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
        <div @click="onClickMenu('IconTemplate')" class="mt-5">
          <li v-if="1">
            <a class="">
              <span class="span-svg " v-html="icon.phone"> </span>
              <span
                v-if="$store.getters['displayqruser/getAside'] !== true"
                :class="
                  $route.name == 'IconTemplate' ? 'menu-text' : 'umenu-text'
                "
                >...</span
              >
            </a>
          </li>
        </div>
      </ul>
    </div>
  </aside>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Menu",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      icon: {
        main: `
        <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 5C2 4.447 2.448 4 3 4H16C16.552 4 17 4.447 17 5C17 5.553 16.552 6 16 6H3C2.448 6 2 5.553 2 5ZM21 11H3C2.448 11 2 11.447 2 12C2 12.553 2.448 13 3 13H21C21.552 13 22 12.553 22 12C22 11.447 21.552 11 21 11ZM12 18H3C2.448 18 2 18.447 2 19C2 19.553 2.448 20 3 20H12C12.552 20 13 19.553 13 19C13 18.447 12.552 18 12 18Z"
                    fill="#41416E"
                  />
                </svg>
      `,
        pen: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9109 6.33008L17.6709 12.0901L9.77091 20C9.40091 20.37 8.93087 20.61 8.42087 20.7L3.68088 21.49C3.62088 21.5 3.5709 21.5 3.5109 21.5C3.2509 21.5 2.99088 21.39 2.80088 21.2C2.57088 20.97 2.4609 20.6401 2.5109 20.3201L3.30088 15.5801C3.39088 15.0701 3.63089 14.6 4.00089 14.23L11.9109 6.33008ZM20.3009 3.69995C19.5309 2.91995 18.5109 2.5 17.4209 2.5C16.3309 2.5 15.3109 2.91995 14.5409 3.69995L13.3309 4.90991L19.0909 10.6699L20.3009 9.45996C21.0809 8.68996 21.5009 7.67008 21.5009 6.58008C21.5009 5.49008 21.0809 4.46995 20.3009 3.69995Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        cadit: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 4.5H7C4.52 4.5 2.5 6.52 2.5 9H21.5C21.5 6.52 19.48 4.5 17 4.5ZM2.5 11V15C2.5 17.48 4.52 19.5 7 19.5H17C19.48 19.5 21.5 17.48 21.5 15V11H2.5ZM10 16H7C6.45 16 6 15.55 6 15C6 14.45 6.45 14 7 14H10C10.55 14 11 14.45 11 15C11 15.55 10.55 16 10 16Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        file: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.75 5.5V3.04004L18.96 7.25H16.5C15.54 7.25 14.75 6.46 14.75 5.5ZM16.5 8.75C14.71 8.75 13.25 7.29 13.25 5.5V2.5H9C6.52 2.5 4.5 4.52 4.5 7V17C4.5 19.48 6.52 21.5 9 21.5H15C17.48 21.5 19.5 19.48 19.5 17V8.75H16.5Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        user: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.35 6.70996C20.35 8.36996 19.25 9.77999 17.74 10.24C17.91 9.68999 18 9.1 18 8.5C18 6.31 16.82 4.3901 15.07 3.3501C15.55 3.1301 16.08 3 16.65 3C18.69 3 20.35 4.65996 20.35 6.70996ZM19.6 11.3C19.18 11.18 18.72 11.24 18.35 11.45C17.91 11.69 17.44 11.8301 16.96 11.8601C16.76 12.1601 16.53 12.45 16.26 12.71C18.19 13.34 19.6 15.01 19.92 17H20.61C21.07 17 21.49 16.7601 21.7 16.3701C21.9 16.0001 22 15.5799 22 15.1599V14.45C22 12.96 21.01 11.67 19.6 11.3ZM8.92999 3.3501C8.44999 3.1301 7.91998 3 7.34998 3C5.30998 3 3.65002 4.65996 3.65002 6.70996C3.65002 8.36996 4.75001 9.77999 6.26001 10.24C6.09001 9.68999 6 9.1 6 8.5C6 6.31 7.17999 4.3901 8.92999 3.3501ZM7.73999 12.71C7.46999 12.45 7.23998 12.1601 7.03998 11.8601C6.55998 11.8301 6.09002 11.69 5.65002 11.45C5.28002 11.24 4.82002 11.18 4.40002 11.3C2.99002 11.67 2 12.96 2 14.45V15.1599C2 15.5799 2.09999 16.0001 2.29999 16.3701C2.50999 16.7601 2.93 17 3.38 17H4.08002C4.40002 15.01 5.80999 13.34 7.73999 12.71ZM15.58 14.0701C15.41 14.0301 15.23 14 15.05 14C14.71 14 14.36 14.09 14.06 14.26C13.42 14.61 12.71 14.79 12 14.79C11.29 14.79 10.58 14.61 9.94 14.26C9.64 14.09 9.29001 14 8.95001 14C8.77001 14 8.58998 14.0301 8.41998 14.0701C6.69998 14.5301 5.5 16.0999 5.5 17.8999V18.77C5.5 19.28 5.63 19.79 5.87 20.23C6.12 20.71 6.61999 21 7.17999 21H16.82C17.38 21 17.88 20.71 18.13 20.23C18.37 19.79 18.5 19.28 18.5 18.77V17.8999C18.5 16.0999 17.3 14.5301 15.58 14.0701ZM16.5 8.5C16.5 6.015 14.485 4 12 4C9.515 4 7.5 6.015 7.5 8.5C7.5 10.985 9.515 13 12 13C14.485 13 16.5 10.985 16.5 8.5Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        package: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.27002 4.50305L15.73 8.89508L13.2 10.0183C12.48 10.3492 11.51 10.3392 10.8 10.0283L3.25 6.60897C3.73 5.90703 4.42998 5.32526 5.28998 4.94421L6.27002 4.50305ZM18.71 4.94421L14.32 2.98885C12.87 2.33705 11.13 2.33705 9.67999 2.98885L8.09003 3.69074L17.54 8.07298L20.75 6.60897C20.27 5.90703 19.57 5.32526 18.71 4.94421ZM2.63 7.9726C2.54 8.30352 2.5 8.64448 2.5 8.99545V15.0522C2.5 16.787 3.56998 18.3414 5.28998 19.1035L9.69 21.0588C10.18 21.2895 10.71 21.4298 11.25 21.5V11.7129C10.88 11.6528 10.52 11.5424 10.19 11.402L2.63 7.9726ZM21.37 7.9726L13.81 11.402C13.48 11.5424 13.12 11.6528 12.75 11.7129V21.5C13.29 21.4298 13.82 21.2895 14.32 21.0588L18.71 19.1035C20.43 18.3414 21.5 16.787 21.5 15.0522V8.99545C21.5 8.64448 21.46 8.30352 21.37 7.9726Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        flag: `
      <svg width="24" height="24" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4475 12.522C15.5255 12.677 15.5165 12.861 15.4255 13.008C15.3345 13.1549 15.1735 13.245 15.0005 13.245H2.49951V18.7503C2.49951 19.3031 2.05151 19.75 1.49951 19.75C0.947512 19.75 0.499512 19.3031 0.499512 18.7503V11.7525L0.500519 11.7474V4.74859C0.500519 2.26837 2.51952 0.25 5.00052 0.25H15.0005C15.1735 0.25 15.3345 0.340032 15.4255 0.486986C15.5165 0.63394 15.5255 0.817723 15.4475 0.972674L12.8945 6.07606C12.6845 6.49593 12.6845 6.99781 12.8945 7.41768L15.4475 12.522Z" 
      fill="#FFFFFF"/>
      </svg>
      `,
        phone: `
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9325 14.558C19.9075 14.414 19.8775 14.2869 19.8465 14.1769C19.6455 13.4589 19.0235 12.9128 18.2615 12.7848L13.9305 12.0639C13.2045 11.9469 12.4725 12.2249 12.0255 12.7929C11.9675 12.8659 11.9115 12.9418 11.7995 13.0658C9.6115 12.1148 7.8825 10.3849 6.9685 8.1459C7.0715 8.0769 7.17052 8.00293 7.26452 7.92593C7.82152 7.47693 8.09753 6.75698 7.98553 6.04898L7.31451 1.79385C7.19751 1.04885 6.68551 0.439963 5.97851 0.205963C5.82551 0.154963 5.64552 0.107 5.43652 0.0689999C4.09652 -0.169 2.72548 0.207969 1.67248 1.11197C0.571483 2.05797 -0.0365002 3.43079 0.00249984 4.87979C0.2245 13.1328 6.86453 19.774 15.1185 19.997C15.1615 19.998 15.2055 19.998 15.2485 19.998C16.6375 19.998 17.9505 19.3968 18.8665 18.3368C19.7785 17.2828 20.1665 15.905 19.9325 14.558ZM17.3535 17.0299C16.8025 17.6679 16.0245 18.0159 15.1715 17.9989C7.98051 17.8049 2.19449 12.0188 2.00049 4.82681C1.97849 3.98281 2.33352 3.18301 2.97552 2.63101C3.45752 2.21701 4.0345 1.99893 4.6325 1.99893C4.78051 1.99893 4.92953 2.01297 5.07953 2.03897C5.18253 2.05897 5.2715 2.08087 5.3385 2.10587L6.00451 6.37491C5.95451 6.41491 5.90249 6.45287 5.84649 6.49087C5.01149 7.05887 4.70249 8.089 5.09649 8.996C6.24949 11.65 8.34651 13.7468 10.9995 14.9008C11.9055 15.2948 12.9375 14.987 13.5065 14.152L13.5995 14.037L17.9195 14.7179C17.9345 14.7719 17.9485 14.832 17.9605 14.902C18.0925 15.655 17.8715 16.4319 17.3535 17.0299Z" 
      fill="#41416E"/>
      </svg>
      `,
        box: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 7.00049V19.9985C19.5 20.5585 19.192 21.0664 18.696 21.3264C18.476 21.4414 18.238 21.4995 17.999 21.4995C17.7 21.4995 17.402 21.4094 17.145 21.2314L12.282 17.8645C12.112 17.7455 11.884 17.7455 11.713 17.8645L6.854 21.2314C6.394 21.5504 5.80099 21.5854 5.30399 21.3264C4.80799 21.0664 4.5 20.5575 4.5 19.9985V7.00049C4.5 4.51949 6.519 2.50049 9 2.50049H15C17.481 2.50049 19.5 4.51949 19.5 7.00049Z" 
        fill="#64748b"/>
        </svg>
        `,
      },
    };
  },
  methods: {
    toggleAside() {
      this.$store.dispatch("displayqruser/toggle_Aside");
    },

    onClickMenu(name) {
      // this.$router.push(link).catch(err => {});
      // this.$router.push({ name: name });
    },
  },
  mounted() {},

  computed: {
    getLocal: function () {
      return this.$store.getters["displayqruser/getLocal"];
    },
  },
  watch: {
    // $route(to, from) {
    //   this.selectedMenu = this.menus.findIndex(menu => menu[2] == to.path);
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mmt-10 {
  margin-top: 90px;
}
.menu-text {
  color: #2f55a4;
  font-weight: 500;
  padding-left: 5px;
}
.umenu-text {
  color: #adb4d2;
  font-weight: 500;
  padding-left: 10px;
}
.span-svg {
  margin-bottom: 5px;
}
.boxicon {
  background: #64748b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px;
}
@media (max-width: 1150px) {
  .sidebar.collapsed {
    left: 0px;
    width: 53px;
    padding: 0px;
  }
}
</style>
