import axios from 'axios';
import authHeader from './auth-header';
import * as constant from './constant';
const API_URL = constant.API_URL;

export async function call_API(para_method,para_url,para_data,para_auth) {
      return new Promise((resolve,reject) => {
          var gethead 
          let getToken
          if(para_auth){
            let getHaveLocal = localStorage.getItem(constant.TOKEN_KEY);
            if(!getHaveLocal){
              window.location = ('/login') 
              return
            } 
            try {
              getToken = atob(atob(localStorage.getItem(constant.TOKEN_KEY)))
            } catch (error) {
              localStorage.removeItem(constant.TOKEN_KEY)
              window.location = ('/login') 
              return
            }
            gethead = { 
              'Authorization': 'Bearer '+getToken, 
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*"
            }
          }else{
            gethead = { 
              'Content-Type': 'application/json'
            }
          }
          
          var config = {}
          if(para_method=='get'){
            let queryString = new URLSearchParams(para_data).toString();
            config = {
              method: para_method,
              url: API_URL+para_url+"?"+queryString,
              headers: gethead
            };
          }else{
            var data = JSON.stringify(para_data);
            config = {
              method: para_method,
              url: API_URL+para_url,
              headers: gethead,
              data : data
            };
          }

          
          axios(config)
          .then(function (response) {
            let getData =JSON.parse(JSON.stringify(response.data))
            resolve(getData);
     
          })
          .catch(function (error) {
            let getError = error.response.data;
            console.log(getError)
            if(getError.status_code && getError.status_code==401){
              localStorage.removeItem(constant.TOKEN_KEY)
              window.location = ('/login') 
            }
            reject(getError);
          });
          
      })
}

export async function call_API_FormData(para_method,para_url,para_data,para_auth) {
  return new Promise((resolve,reject) => {
      var gethead 
      let getToken
      if(para_auth){
        let getHaveLocal = localStorage.getItem(constant.TOKEN_KEY);
        if(!getHaveLocal){
          window.location = ('/login') 
          return
        } 
        try {
          getToken = atob(atob(localStorage.getItem(constant.TOKEN_KEY)))
        } catch (error) {
          localStorage.removeItem(constant.TOKEN_KEY)
          window.location = ('/login') 
          return
        }
        gethead = { 
          'Authorization': 'Bearer '+getToken,
          'Content-Type': 'multipart/form-data'
        }
      }else{
        gethead = { 
          'Content-Type': 'multipart/form-data'
        }
      }
      
    
      let config = {
        method: para_method,
        url: API_URL+para_url,
        headers: gethead,
        data : para_data
      };
      

      
      axios(config)
      .then(function (response) {
        let getData =JSON.parse(JSON.stringify(response.data))
        // console.log(getData)
        resolve(getData);
 
      })
      .catch(function (error) {
        let getError = error.response.data;
        console.log(getError)
        if(getError.status_code && getError.status_code==401){
          localStorage.removeItem(constant.TOKEN_KEY)
          window.location = ('/login') 
        }
        reject(getError);
      });
      
  })
}