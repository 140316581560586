export const socket_URL = process.env.VUE_APP_SOCKET_URL;
export const API_URL = process.env.VUE_APP_API_ENDPOINT;
export const imageUrl = process.env.VUE_APP_IMAGE_URL;
export const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;
// export const socket_URL = "http://localhost:5011";
// export const API_URL = "http://localhost:5011/api/";
// export const fileUrl = "https://workflow-api-dv.ecotech.co.th/api/file/getFile/";



// workflow-api-dv.ecotech.co.th