import { createStore } from 'vuex'
import { display } from './display.module';
import { displayqruser } from './displayqruser.module';
import { localstorage } from './localstorage.module';
import { service } from './service.module';
import { auth } from './auth.module';
import { jobdata } from './jobdata.module'
import { user } from './user.module'
export default createStore({
  modules: {
    auth: auth,
    display: display,
    displayqruser: displayqruser,
    localstorage: localstorage,
    service: service,
    jobdata: jobdata, user: user
  }
})
