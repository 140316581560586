<template>
  <div class="row m-0 px-0  ">
    <div
      v-if="text == 'wait' && disable == 'N'"
      class="col-12 px-3 py-1 d-flex justify-content-center bgwait font"
    >
      {{ text }}
    </div>
    <div
      v-if="text == 'active' && disable == 'N'"
      class="col-12 px-3 d-flex py-1  justify-content-center bgactive font"
    >
      {{ text }}
    </div>
    <div
      v-if="disable == 'Y'"
      class="col-12 px-3 d-flex py-1  justify-content-center bgdisable dfont"
    >
      {{ text }}
    </div>
    <!-- <div
      v-if="disable == 'Y'"
      class="col-12 py-1 px-md-4 px-2 d-flex justify-content-center bgdisable dfont"
    >
      {{ text }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "WaitActive",
  props: ["text", "disable"],
};
</script>

<style scpoed>
.bgwait {
  background: #fbbf24;
  border-radius: 50px;
}
.bgactive {
  background: #6ee7b7;
  border-radius: 50px;
}
.bgdisable {
  background: #e2e8f0;
  border-radius: 50px;
}
.font {
  font-size: 12px;
  color: #2a6b86;
  font-weight: 400;
}
.dfont {
  font-size: 12px;
  color: #cbd5e1;
  font-weight: 400;
}
</style>