<template>
  <div class="m-0 p-0 btn bg">
    <div class="m-0 px-sm-0 px-md-2 px-lg-3 px-xl-4 my-4 taxt">
      <div class="px-5 m-0">
        <div class="px-3 m-0">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonDetail",
  props: ["text"],
};
</script>

<style scpoed>
.bg {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.taxt {
  font-weight: 400;
  font-size: 1.5em;
  color: #000000;
}
</style>