
export const user = {
    namespaced: true,
    state: {
        user: [],

    },
    getters: {
        getuser(state) {
            return state.user;
        },


    },
    mutations: {
        SET_user(state, value) {
            state.user = value
        },


    },
    actions: {
        actionc_addusre({ commit }, value) {

            let user = this.getters['user/getuser']
            let data = user

            if (data.length == 0) {
                data.push({ id: value.id, name: `${value.name} ${value.lname}` })
                commit("SET_user", data);
            } else {
                const index = data.findIndex((e) => e.id === value.id);

                if (index === -1) {
                    // ไม่เจอ

                    data.push({ id: value.id, name: `${value.name} ${value.lname}` })
                    commit("SET_user", data);
                } else {
                    // เจอ


                }

            }

        },
        actionc_removrusre({ commit }, value) {
            let user = this.getters['user/getuser']
            let data = user
            const index = data.findIndex((e) => e.id === value.id);
            if (index !== -1) {
                //  เจอ
                data.splice(index, 1)
                
                commit("SET_user", data);
            }
        },
        actionc_reuser({ commit }) {
            commit("SET_user", []);
        },

    },
    modules: {
    }
};
