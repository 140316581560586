<template>
  <main class="main-content" style="background-color: white">
    <div class="signUP-admin h-100">
      <div class="container-fluid h-100">
        <div class="row mx-0 p-0 align-items-center" style="min-height: 100vh">
          <div class="col-12 mx-0 pl-5 position-relative">
            <p class="title">Control center</p>
            <p class="subtitle">Ecotech part</p>
          </div>

          <img class="w-100 eco-img-login-logo" src="/images/ecotech.png" alt="" />
        </div>
      </div>
    </div>
    <!-- End: .signUP-admin  -->
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "LoginLoad",

  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.title {
  color: #64748b;
  text-shadow: 5px 10px 11px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 8.125em;
}
.subtitle {
  color: #929ab7;
  text-shadow: 5px 10px 11px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 6em;
}
.h-vw-100 {
  height: 100vw;
}
.eco-img-bg {
  width: 140%;
  /* height: 80vh; */
}
.eco-img-login-logo {
  position: absolute;
  top: 50%;
  left: 87%;
  transform: translate(-50%, -50%);
  max-width: 1022px;
}
@media only screen and (max-width: 1600px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    max-width: 400px;
  }
}
@media only screen and (max-width: 1300px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    max-width: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .eco-img-bg {
    width: 100%;
    height: auto;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150px;
  }
}

.card-body {
  border: 1px rgba(51, 51, 51, 0.25) solid;
  border-radius: 25px;
}
.btn-eco {
  background: rgb(47, 85, 164);
  background: linear-gradient(
    180deg,
    rgba(47, 85, 164, 1) 0%,
    rgba(53, 43, 108, 1) 100%
  );
  color: white;
}
.font-signin {
  font-size: 1rem;
}
.card-flex-login {
  height: 100%;
}
.card-login {
  max-width: 500px;
}
</style>