
export const jobdata = {
    namespaced: true,
    state: {
        jobStageNodeId: null,
        gendata:null,
        canaction:null
    },
    getters: {
        getjobStageNodeId(state) {
            return state.jobStageNodeId;
        },
        getgendata(state) {
            return state.gendata;
        },
        getcanaction(state) {
            return state.canaction;
        },


    },
    mutations: {
        SET_jobStageNodeId(state, value) {
            state.jobStageNodeId = value
        },
        SET_getgendata(state, value) {
            state.gendata = value
        },
        SET_getcanaction(state, value) {
            state.canaction = value
        },

    },
    actions: {
        actionc_jobStageNodeId({ commit }, value) {
            commit("SET_jobStageNodeId", value);
        },
        actionc_getgendata({ commit }, value) {
            commit("SET_getgendata", value);
        },
        actionc_getcanaction({ commit }, value) {
            // console.log(value);
            commit("SET_getcanaction", value);
        },

    },
    modules: {
    }
};
