// import AuthService from '../services/auth.service';
import * as main from '@/services/main.service';
import * as constant from '@/services/constant';
import jwt_decode from 'jwt-decode';
const user = main.parseJwtByLocalStorage(constant.TOKEN_KEY);
const initialState = user ?
    { status: { loggedIn: true }, user } :
    { status: { loggedIn: false }, user: null };
export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({ commit },{ token, callback }) {
            let getEncodeAccess_token = btoa(btoa(token));
            localStorage.setItem(constant.TOKEN_KEY, getEncodeAccess_token);
            let getUser = await jwt_decode(token);
            await commit('loginSuccess', getUser);
            callback();
        },
        logout({ commit }) {
            localStorage.removeItem(constant.TOKEN_KEY)
            commit('logout')
        },
        NotFoundUser({ commit }) {
            localStorage.removeItem(constant.TOKEN_KEY)
            commit('loginFailure')
        }
    },
    getters: {
        getloggedIn(state) {
            return state.status.loggedIn;
        },
        getUser(state) {
            return state.user;
        }
    }, 
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    }
};
