import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import store from '../store/index'
import App from '../views/workflow/App.vue'
import Appuser from '../views/workflow-user-mobile/App.vue'
import * as main from '../services/main.service';
import * as constant from '../services/constant';
const routes = [

  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },

  // { path: '/', 
  //   beforeEnter: async (to, from, next) => {
  //     try {
  //       let getUser = store.getters['auth/getUser']
  //       console.log(getUser)
  //       if (!getUser) {
  //         next({ name: "Welcome" });
  //       } else {
  //         next({ name: "login" });
  //       }
  //     } catch (error) {
  //       next({ name: "login" });
  //     }
  //   }, 
  // },

  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      try {
        const clientId = process.env.VUE_APP_ECOTECH_SSO_CLIENT_ID;
        window.location.href = `https://login-dev.ecotechpart.com/Login?clientid=${clientId}`;
      } catch (error) {
        next();
      }
    },
  },
  {
    path: '/redirect',
    name: 'Redirect',
    beforeEnter: async (to, from, next) => {
      try {
        if (to.query.token) {

          let token = to.query.token;
          if (process.env.NODE_ENV != 'production') {
            console.log(token)
          }
          store.dispatch("auth/login", {
            token,
            callback: () => {
              next({ name: "Welcome" });
            },
          });

          // next({ name: "Welcome" });
        } else {
          next({ name: "login" });
        }
      } catch (error) {
        next({ name: "login" });
      }
    },

  },
  {
    path: '/',
    component: App,
    children: [
      {
        path: '/',
        name: 'home',
        beforeEnter: async (to, from, next) => {
          next({ name: "login" });
        },
      },
      {
        path: '/welcome',
        name: 'Welcome',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Welcome.vue" */'../views/workflow/views/Welcome.vue')
      },
      {
        path: '/ProjectPage',
        name: 'ProjectPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ProjectPage.vue" */'../views/workflow/views/ProjectPage.vue')
      },
      {
        path: '/JobPage',
        name: 'JobPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "JobPage.vue" */'../views/workflow/views/JobPage.vue')
      },
      {
        path: '/WorkFlow',
        name: 'WorkFlowPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "WorkFlowPage.vue" */'../views/workflow/views/WorkFlowPage.vue')
      },
      {
        path: '/DocumentTemplate',
        name: 'DocumentTemplate',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "DocumentTemplate.vue" */'../views/workflow/views/DocumentTemplate.vue')
      },
      {
        path: '/IconTemplate',
        name: 'IconTemplate',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "IconTemplate.vue" */'../views/workflow/views/IconTemplate.vue')
      },
      {
        path: '/JobDetail/:id/:mydata',
        name: 'JobDetailPage',
        props: true,
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "JobDetailPage.vue" */'../views/workflow/views/JobDetailPage.vue')
      },
      {
        path: '/ShortUrl',
        name: 'ShortUrlPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ShortUrlPage.vue" */'../views/workflow/views/ShortUrlPage.vue')
      },
      {
        path: '/ShortUrlPage_BK_TEE',
        name: 'ShortUrlPage_BK_TEE',
        meta: { requiresAuth: true, requiresPlant: true },
        component: () => import(/* webpackChunkName: "ShortUrlPage.vue" */'../views/workflow/views/ShortUrlPage_BK_TEE.vue')
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Dashboard.vue" */'../views/workflow/views/Dashboard.vue')
      },
      {
        path: '/monthly',
        name: 'Monthly',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Dashboard.vue" */'../views/workflow/views/Monthly.vue')
      },
      {
        path: '/yearly',
        name: 'Yearly',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Yearly.vue" */'../views/workflow/views/Yearly.vue')
      },
      {
        path: '/cleaning',
        name: 'Cleaning',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Cleaning.vue" */'../views/workflow/views/Cleaning.vue')
      },
    ]
  },
  {
    path: '/appuser',
    name: 'Appuser',
    component: Appuser,
    children: [
      {
        path: 'qruser/:id',
        props: true,
        name: 'QrUser',
        // meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "QrUser.vue" */'../views/workflow-user-mobile/views/QrUser.vue')
      },
    ]
  },

  {
    path: '/AppCustomer',
    name: 'AppCustomer',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "WorkFlowPage.vue" */'../components/App/Customer/AppCustomer.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: "/login"
  }
]
//ส่ง jwt ไป vetify ทุกครั้งก่อนเข้า page เพื่อให่มั่นใจว่า kry ยังไม่หมดอายุหรือโดนปลอมแปลงถ้าไม่ผ่าน ให้ลบ และ rediract ไป login
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let getUser = store.getters['auth/getUser']
    //console.log(getUser)
    if (!getUser) {
      store.dispatch('auth/NotFoundUser')
      next({
        path: '/login'
      })
      return
    } else {

    }
  }
  if (to.matched.some(record => record.meta.requiresPlant)) {
    let GET_plantId = store.getters['localstorage/GET_plantId']
    // console.log(GET_plantId)
    if (!GET_plantId) {
      alert("กรุณาเลือก Plant ก่อนครับ")
      next({
        path: '/welcome'
      })
      return
    } else {

    }
  }


  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters['auth/getUser']
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{

  //   }

  // }

  next() // make sure to always call next()!

})
export default router
