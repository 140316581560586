<template>
  <div class="row m-0 p-0 w-100" v-if="$store.getters['auth/getUser'] == null">
    <div class="col-12 m-0 p-0" v-if="load !== true"><LoginMain /></div>
    <div class="col-12 m-0 p-0" v-if="load === true"><LoginLoad /></div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as serviceAPI from "../services/API.service";
import * as serviceMain from "../services/main.service";
import LoginMain from "@/components/Login/LoginMain.vue";
import LoginLoad from "@/components/Login/LoginLoad.vue";
export default {
  name: "login",
  components: { LoginMain, LoginLoad },
  data: function () {
    return {
      load: true,
    };
  },
  methods: {
    onload() {
      setTimeout(() => {
        this.load = false;
      }, 2000);
    },
  },
  mounted() {
    this.onload();
  },
};
</script>

<style scoped>
.h-vw-100 {
  height: 100vw;
}
.eco-img-bg {
  width: 140%;
  /* height: 80vh; */
}
.eco-img-login-logo {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  max-width: 500px;
}
@media only screen and (max-width: 1600px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    max-width: 400px;
  }
}
@media only screen and (max-width: 1300px) {
  .eco-img-bg {
    width: 140%;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    max-width: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .eco-img-bg {
    width: 100%;
    height: auto;
  }
  .eco-img-login-logo {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150px;
  }
}

.card-body {
  border: 1px rgba(51, 51, 51, 0.25) solid;
  border-radius: 25px;
}
.btn-eco {
  background: rgb(47, 85, 164);
  background: linear-gradient(
    180deg,
    rgba(47, 85, 164, 1) 0%,
    rgba(53, 43, 108, 1) 100%
  );
  color: white;
}
.font-signin {
  font-size: 1rem;
}
.card-flex-login {
  height: 100%;
}
.card-login {
  max-width: 500px;
}
</style>

