
export const display = {
  namespaced: true,
  state: {
    isAside: true,
    local:'en',
    pageName:''
  },  
  getters: {
    getAside(state) {
      return state.isAside;
    },
    getLocal(state) {
      return state.local;
    },
    GET_pageName(state) {
      return state.pageName;
    }
  },
  mutations: {
    SET_Aside(state) {
      state.isAside =!state.isAside
    },
    SET_Local(state,local) {
      state.local = local
    },
    MU_pageName(state,value) {
      state.pageName = value
    },
  },
  actions: {
    toggle_Aside({ commit }) {
      commit("SET_Aside");
    },
    setLocal({ commit },value) {
      commit("SET_Local",value);
    }, 
    SET_pageName({ commit },value) {
      commit("MU_pageName",value);
    },
  },
  modules: {
  }
};
