import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './plugins/i18n';
import VueHtml2Canvas from 'vue-html2canvas';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { io } from "socket.io-client";
import * as constant from './services/constant';

import VueClipboard from 'vue-clipboard2'


// createApp(App).use(store).use(router).use(VueSweetalert2).mount('#app')


// prototype
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import dateFormat from "dateformat";
 
 

// end prototype


const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(VueHtml2Canvas)
app.use(router)
app.use(VueSweetalert2)
app.use(VueClipboard)
// app.config.globalProperties.$socket = io.connect(constant.socket_URL);
app.config.globalProperties.$CONSTANT = constant
app.config.globalProperties.$API = serviceAPI
app.config.globalProperties.$MAIN = serviceMain
app.config.globalProperties.$DF = dateFormat
 
app.mount('#app')