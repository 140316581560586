<template>
  <div class="row m-0 p-0">
    <div
      :class="due == 'LATE' ? 'bglate' : due == 'URGENT' ? 'bgurgent' : ''"
      class="col-12 p-0 m-0"
    >
      <!-- -------------- 1--------------------------- -->
      <div class="row mx-0 px-5 pt-5">
        <!-- ------------------------------------------------------ -->
        <div class="col-6 m-0 p-0">
          <!-- <div class="row m-0 p-0">
            <div class="mx-0 mt-2 p-0">
              <img class="mr-5" src="/images/Vector.png" alt="" />
            </div>
            <div class="m-0 p-0">
              <WaitActive :text="status" disable="N" />
            </div>
          </div> -->
        </div>
        <!-- ------------------------------------------------------ -->
        <div class="col-6 m-0 p-0 d-flex justify-content-end">
          <p
            class="font-Roboto"
            :class="
              due == 'LATE' ? 'textlate' : due == 'URGENT' ? 'texurgent' : ''
            "
          >
            {{ due }}
          </p>
        </div>
      </div>
      <!-- -------------- 2--------------------------- -->
      <div class="row mx-0 mb-5 p-0">
        <div class="col-12 col-md-6 m-0 p-0">
          <img class="img" :src="img" alt="" />
        </div>
        <div class="col-12 col-md-6 m-0 p-0">
          <div class="col-12 mx-0 mt-5 p-0 d-flex justify-contnet-center">
            <p class="m-0 p-0 id">Job ID : {{ id }}</p>
          </div>
          <div class="col-12 mx-0 mt-2 p-0 d-flex justify-contnet-center">
            <p class="m-0 p-0 tital">{{ tital }}</p>
          </div>
          <div class="col-12 mx-0 mt-2 p-0 d-flex justify-contnet-center">
            <p class="m-0 p-0 date">{{ date }}</p>
          </div>
          <div class="col-12 mx-0 mt-4 p-0">
            <ButtonDetail text="DETAILS" @click="goto(id)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaitActive from "@/components/Common/WaitActive.vue";
import ButtonDetail from "@/components/Job/ButtonDetail.vue";
export default {
  name: "LeftBox",
  props: ["status", "img", "due", "id", "tital", "date", "detail"],
  components: { WaitActive, ButtonDetail },
  methods: {
    goto(name) {
      let convertid = this.$MAIN.Base64.encode(name);
      this.$router.push({ name: "JobDetailPage", params: { id: convertid } });
    },
  },
};
</script>

<style scoped>
.bglate {
  background: linear-gradient(66.39deg, #fcd34d -7.07%, #ffffff 117.55%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
}
.bgurgent {
  background: linear-gradient(
    66.39deg,
    rgba(228, 44, 44, 0.84) -7.07%,
    #ffffff 117.55%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
}

.textlate {
  font-weight: 400;
  font-size: 3em;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1px #fa8b0c;
}
.texurgent {
  font-weight: 400;
  font-size: 3em;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1px #ff0000;
}
.id {
  color: #475569;
  font-weight: 700;
  font-size: 2.25em;
}
.tital {
  font-weight: 600;
  font-size: 1.5em;
  color: #0f172a;
}
.date {
  font-weight: 400;
  font-size: 0.75em;
  color: #64748b;
}
/* .img {
  width: 386.16px;
} */
@media screen and (max-width: 1600px) {
  .img {
    width: 386.16px;
  }
}
@media screen and (max-width: 1400px) {
  .img {
    width: 300px;
  }
}
@media screen and (max-width: 1300px) {
  .img {
    width: 280px;
  }
}
@media screen and (max-width: 1200px) {
  .img {
    width: 250px;
  }
}
 
</style>