
export const displayqruser = {
  namespaced: true,
  state: {
    isAside: true,
  
  },  
  getters: {
    getAside(state) {
      return state.isAside;
    },
 
  },
  mutations: {
    SET_Aside(state) {
      state.isAside =!state.isAside
    },
 
  },
  actions: {
    toggle_Aside({ commit }) {
   
      commit("SET_Aside");
    },
    
  },
  modules: {
  }
};
